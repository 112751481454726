<template>
  <div v-if="task_term_date">
    <template v-if="enableTiming">
      <label :class="'label label-' + task_term_label">
        <i class="far fa-calendar" style="margin-right: 3px;"></i>
        <i class="far fa-clock"></i>
        <span class="font-medium">
          {{ task_term_date }} {{ task_term_time }}
        </span>
      </label>
      <br />
      <span v-if="timeRemaining.timeDifference > 0">
        Осталось:
        <span class="font-medium">
          {{ timeRemaining.days }} д. {{ timeRemaining.hours }} ч. {{ timeRemaining.minutes }} мин.
        </span>
      </span>
      <span v-else>
        <label class="label label-danger">
          Время истекло
        </label>
      </span>

      <hr class="mt-1 mb-1" />
    </template>
  </div>
  <div v-else>
    <template v-if="enableTiming">
      <label v-if="timeRemaining.timeDifference > 0"
             :class="'label label-' + ((timeRemaining.hours < 1 && timeRemaining.minutes > 0) ? 'warning' : 'success' )">
        Осталось:
        <span class="font-medium">
          {{ timeRemaining.hours }} ч. {{ timeRemaining.minutes }} мин.
        </span>
      </label>
      <label v-else class="label label-danger">
        Время истекло
      </label>

      <hr class="mt-1 mb-1" />
    </template>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "task-item-term-block",

  props: {
    task_term_date: {
      type: String,
    },
    task_term_time: {
      type: [String, null],
    },
    task_term_label: {
      type: String,
      default: "default",
    },
    task_updated_date: {
      type: String,
    },
    task_type_id: {
      type: Number,
    },
    task_type_state_id: {
      type: [Number, null],
    },
  },

  data() {
    return {
      timeRemaining: {
        timeDifference: 0,
        days: 0,
        hours: 0,
        minutes: 0,
      },
      enableTiming: true,
    };
  },

  mounted() {
    if (this.task_term_date && this.task_term_time) {
      this.countdownWithCalendar();

      setInterval(() => {
        this.countdownWithCalendar();
      }, 60000);
    } else {
      this.countdownWithoutCalendar();

      setInterval(() => {
        this.countdownWithoutCalendar();
      }, 60000);
    }
  },

  methods: {
    isWorkingHours() {
      const now = moment();
      const startOfWorkDay = moment().set("hour", 9).set("minute", 0).set("second", 0);
      const endOfWorkDay = moment().set("hour", 20).set("minute", 0).set("second", 0);

      return now.isBetween(startOfWorkDay, endOfWorkDay);
    },
    countdownWithCalendar() {
      if (!this.task_type_state_id) {
        if ([10, 11, 12].includes(this.task_type_id)) {
          this.enableTiming = false;
        }
      } else {
        if ([3, 5, 6, 7, 8, 9, 16, 17, 18, 19, 20, 26, 27].includes(this.task_type_state_id)) {
          this.enableTiming = false;
        }
      }

      let taskUpdatedDate = moment((this.task_term_date + " " + this.task_term_time), "DD.MM.YY HH:mm");
      const endTime = taskUpdatedDate.toDate().getTime();

      const now = new Date().getTime();
      const timeDifference = endTime - now;

      if (timeDifference > 0) {
        this.timeRemaining.timeDifference = timeDifference;
        this.timeRemaining.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        this.timeRemaining.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.timeRemaining.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      }
    },
    countdownWithoutCalendar() {
      let hoursLimit = 7;

      if (!this.task_type_state_id) {
        if ([4, 14].includes(this.task_type_id)) {
          hoursLimit = 12;
        } else if (this.task_type_id === 2) {
          hoursLimit = 24;
        } else if (this.task_type_id === 3) {
          hoursLimit = 5;
        } else if (this.task_type_id === 9) {
          hoursLimit = 48;
        } else if ([10, 11, 12].includes(this.task_type_id)) {
          this.enableTiming = false;
        }
      } else {
        if ([3, 5, 6, 7, 8, 9, 16, 17, 18, 19, 20, 26, 27].includes(this.task_type_state_id)) {
          this.enableTiming = false;
        } else if (this.task_type_state_id === 1) {
          hoursLimit = 0.5;
        } else if (this.task_type_state_id === 2) {
          hoursLimit = 24;
        } else if (this.task_type_state_id === 4) {
          hoursLimit = 24;
        } else if (this.task_type_state_id === 10) {
          hoursLimit = 1;
        } else if (this.task_type_state_id === 11) {
          hoursLimit = 24;
        } else if (this.task_type_state_id === 12) {
          hoursLimit = 12;
        } else if (this.task_type_state_id === 13) {
          hoursLimit = 2;
        } else if (this.task_type_state_id === 14) {
          hoursLimit = 24;
        } else if (this.task_type_state_id === 15) {
          hoursLimit = 48;
        } else if (this.task_type_state_id === 21) {
          hoursLimit = 3;
        } else if (this.task_type_state_id === 22) {
          hoursLimit = 24;
        } else if (this.task_type_state_id === 23) {
          hoursLimit = 4;
        } else if (this.task_type_state_id === 24) {
          hoursLimit = 24;
        } else if (this.task_type_state_id === 25) {
          hoursLimit = 48;
        }
      }

      let taskUpdatedDate = moment(this.task_updated_date, "DD.MM.YY HH:mm");

      const endTime = taskUpdatedDate
        .add(hoursLimit, "hours")
        .toDate()
        .getTime();
      const now = new Date().getTime();

      let timeDifference;
      if (!this.isWorkingHours()) {
        timeDifference = endTime - moment().set("hour", 9).set("minute", 0).set("second", 0).add(1, "day")
      } else {
        timeDifference = endTime - now;
      }

      if (timeDifference > 0) {
        this.timeRemaining.timeDifference = timeDifference;
        this.timeRemaining.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.timeRemaining.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      }

      if (this.timeRemaining.timeDifference > 0) {
        if (this.timeRemaining.hours < 1 && this.timeRemaining.minutes > 0) {
          this.$emit('set-time-status-color', 'rgba(255, 211, 81, 0.1)');
        } else {
          this.$emit('set-time-status-color', 'rgba(40, 167, 69, 0.1)');
        }
      } else {
        this.$emit('set-time-status-color', 'rgba(220, 53, 69, 0.1)');
      }
    },
  },
};
</script>

<style scoped>

</style>
